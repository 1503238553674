import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import Button from '../../../shared/button';
import SelectQuestionField from './select_question_form/select_question_field';

const newChoice = () => ({ body: '', role: 'basic_role', tempKey: uuidv4() });
const otherChoice = () => ({ body: 'その他(自由記入)', role: 'with_string_role', tempKey: uuidv4() });

class SelectQuestionForm extends React.Component {
  constructor(props) {
    super(props);
    const { question } = this.props;
    this.state = {
      choices: question.choices && question.choices.length > 0 ? question.choices : [newChoice()],
    };
    this.addChoice = this.addChoice.bind(this);
    this.addOtherChoice = this.addOtherChoice.bind(this);
  }

  addChoice() {
    const { choices } = this.state;
    this.setState({ choices: choices.concat([newChoice()]) });
  }

  addOtherChoice() {
    const { choices } = this.state;
    this.setState({ choices: choices.concat([otherChoice()]) });
  }

  removeChoice(choice) {
    const { choices } = this.state;
    if (choice.id) {
      this.setState({
        choices: choices.map((c) => {
          if (c.id && c.id === choice.id) {
            return Object.assign({}, c, { destroyed: true });
          }
          return c;
        }),
      });
      return;
    }
    this.setState({
      choices: choices.filter(c => c.tempKey !== choice.tempKey),
    });
  }

  render() {
    const { choices } = this.state;
    const normalChoices = choices.filter(c => !['with_string_role', 'blank_role'].includes(c.role));
    const otherChoices = choices.filter(c => c.role === 'with_string_role');
    const existsActiveOtherChoice = otherChoices.some(c => c && !c.destroyed);
    const activeFieldCount =
      normalChoices.filter(c => c && !c.destroyed).length
      + (existsActiveOtherChoice ? 1 : 0);
    return (
      <div className="row">
        <div className="col-md-4">
          選択肢
        </div>
        <div className="col-md-8">
          {normalChoices.concat(otherChoices).map((choice, index) => {
            if (!choice) {
              return null;
            }
            return (
              <SelectQuestionField
                key={choice.id || choice.tempKey}
                choice={choice}
                index={index}
                showDeleteButton={activeFieldCount > 1}
                choiceRemoved={() => this.removeChoice(choice)}
              />
            );
          })}
          <div className="row">
            <div className="col-md-6 py-1">
              <Button
                title="選択肢追加"
                className="btn btn-light btn-block"
                onClick={this.addChoice}
              />
            </div>
            {!existsActiveOtherChoice && (
              <div className="col-md-6 py-1">
                <Button
                  title="その他選択肢追加"
                  className="btn btn-light btn-block"
                  onClick={this.addOtherChoice}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

SelectQuestionForm.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
SelectQuestionForm.defaultProps = {
};
export default SelectQuestionForm;
