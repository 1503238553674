import React, { ReactElement } from "react";
import jstz from "jstimezonedetect";
import { List } from "immutable/dist/immutable";
import QuestionsForm from "./last_page_additional_questions/questions_form";
import UserAttributesForm from "./last_page_additional_questions/user_attributes_form";
import HiddenForm from "../../../shared/form/hidden_form";
import DevelopmentRequestForm from "./last_page_additional_questions/development_request_form";
import { QuestionnaireNewResponse } from "../../../../models/QuestionnaireApi";
import { QuestionnaireNewUserResponse } from "../../../../models/UserApi";
import { UserProfileMyselfResponse } from "../../../../models/UserProfile";
import { EventResponse } from "../../../../models/Event";
import { MemberResponse } from "../../../../models/Member";
import { DepartmentResponse } from "../../../../models/Department";
import { UserSettingResponse } from "../../../../models/UserSetting";
import { IndustryCategoryWithIndustriesResponse } from "../../../../models/IndustryCategoryApi";

interface Props {
  isConsumer: boolean;
  questionnaire: QuestionnaireNewResponse;
  headerAreaIcon: string;
  user: QuestionnaireNewUserResponse | null;
  isProfileInputAvailable: boolean;
  userProfile: UserProfileMyselfResponse | null;
  birthYearOptions: Array<{ key: string; value: string }>;
  birthMonthOptions: Array<{ key: string; value: string }>;
  sexOptions: Array<{ key: string; value: string }>;
  finalEducationOptions: Array<{ key: string; value: string }>;
  professionOptions: Array<{ key: string; value: string }>;
  personalAnnualIncomeOptions: Array<{ key: string; value: string }>;
  householdAnnualIncomeOptions: Array<{ key: string; value: string }>;
  maritalStatusOptions: Array<{ key: string; value: string }>;
  childStatusOptions: Array<{ key: string; value: string }>;
  prefectureOptions: Array<{ key: string; value: string }>;
  countryOptions: Array<{ key: string; value: string }>;
  event: EventResponse | null;
  isLoggedIn: boolean;
  selfIntroductionQuestions: unknown;
  selfIntroductionAnswers: unknown;
  departmentOptions: Array<{ key: string; value: string }>;
  managerialPositionOptions: Array<{ key: string; value: string }>;
  members: MemberResponse[];
  departments: DepartmentResponse[];
  userSetting: UserSettingResponse;
  isDevelopmentRequestFormVisible: boolean;
  errorMessages: string[];
  industryCategories: IndustryCategoryWithIndustriesResponse[];
  previousQuestionnaire: QuestionnaireNewResponse | null;
}

export default function LastPageAdditionalQueistions(
  props: Props
): ReactElement {
  const {
    questionnaire,
    userProfile,
    isConsumer,
    sexOptions,
    user,
    isLoggedIn,
    finalEducationOptions,
    professionOptions,
    personalAnnualIncomeOptions,
    householdAnnualIncomeOptions,
    maritalStatusOptions,
    prefectureOptions,
    birthYearOptions,
    birthMonthOptions,
    selfIntroductionQuestions,
    selfIntroductionAnswers,
    industryCategories,
    isProfileInputAvailable,
    managerialPositionOptions,
    departmentOptions,
    members,
    departments,
    userSetting,
    event,
    childStatusOptions,
    countryOptions,
    isDevelopmentRequestFormVisible,
  } = props;

  const lastPageAdditionalQuestions = () => {
    let nodes = List();
    nodes = nodes.concat(
      <QuestionsForm key="question_form" answers={questionnaire.answers} />
    );
    nodes = nodes.concat(
      <UserAttributesForm
        key="user_profile_form"
        isConsumer={isConsumer}
        user={user}
        questionnaire={questionnaire}
        isProfileInputAvailable={isProfileInputAvailable}
        userProfile={userProfile}
        isLoggedIn={isLoggedIn}
        birthYearOptions={birthYearOptions}
        birthMonthOptions={birthMonthOptions}
        sexOptions={sexOptions}
        finalEducationOptions={finalEducationOptions}
        professionOptions={professionOptions}
        personalAnnualIncomeOptions={personalAnnualIncomeOptions}
        householdAnnualIncomeOptions={householdAnnualIncomeOptions}
        maritalStatusOptions={maritalStatusOptions}
        childStatusOptions={childStatusOptions}
        prefectureOptions={prefectureOptions}
        selfIntroductionQuestions={selfIntroductionQuestions}
        selfIntroductionAnswers={selfIntroductionAnswers}
        industryCategories={industryCategories}
        departmentOptions={departmentOptions}
        managerialPositionOptions={managerialPositionOptions}
        countryOptions={countryOptions}
        members={members}
        departments={departments}
        userSetting={userSetting}
        event={event}
      />
    );
    if (isDevelopmentRequestFormVisible) {
      nodes = nodes.concat(
        <DevelopmentRequestForm key="development_request_form" />
      );
    }
    return nodes;
  };

  return (
    <>
      {lastPageAdditionalQuestions()}
      <HiddenForm
        scope="questionnaire"
        relatedModelName="user"
        columnName="time_zone"
        value={jstz.determine().name()}
      />
    </>
  );
}
