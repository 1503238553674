import React, { ReactElement, ReactNode } from "react";
import SelectField from "./fields/select_field";
import { fieldId, fieldName, i18nKey } from "./utils/attribute_utils";
import { errorMessages } from "./utils/message_utils";

interface Props {
  model: object;
  scope: string;
  relatedModelName?: string;
  columnName: string;
  options: Array<{ key: string; value: string }>;
  loadOptions?: (
    searchWord: string
  ) => Promise<Array<{ key: string; value: string }>>;
  option?: {
    include_blank?: string;
  };
  label?: string | ReactNode;
  labelColor?: string;
  index?: number;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  selectedKey?: string;
  defaultKey?: string;
  disabled?: boolean;
  hideLabel?: boolean;
  required?: boolean;
  message?: string;
}

export default function SelectForm(props: Props): ReactElement {
  const {
    model,
    scope,
    relatedModelName,
    columnName,
    options,
    loadOptions,
    option,
    label,
    labelColor = "#212529",
    index,
    onChange,
    defaultKey,
    disabled,
    hideLabel,
    selectedKey,
    required,
    message,
  } = props;

  function viewLabel(): string | ReactNode {
    return label || window.I18n.t(i18nKey(scope, relatedModelName, columnName));
  }

  return (
    <React.Fragment>
      <div className="form-group row">
        {!hideLabel && (
          <label
            className="col-sm-4 col-form-label"
            htmlFor={fieldId(scope, relatedModelName, columnName, index)}
            style={{ color: labelColor }}
          >
            {viewLabel()}
            {required && <span> ※ 必須</span>}
          </label>
        )}
        <div className={`col-sm-${hideLabel ? 12 : 8}`}>
          <SelectField
            id={fieldId(scope, relatedModelName, columnName, index)}
            name={fieldName(scope, relatedModelName, columnName, index)}
            options={options}
            loadOptions={loadOptions}
            selectedKey={selectedKey || model[columnName] || defaultKey}
            option={option}
            onChange={onChange}
            disabled={disabled}
            required={required}
          />
          {errorMessages(model, columnName, viewLabel()).map((m, i) => (
            <div key={m} className="invalid-feedback d-block">
              <span>{m}</span>
            </div>
          ))}
          {message && (
            <div className="color__gray font__size-13">{message}</div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
