import React, { ReactElement, useEffect, useState } from "react";
import { List } from "immutable";
import { animateScroll } from "react-scroll";
import { withCookies, Cookies } from "react-cookie";
import ActionButton from "./happiness_questionnaire_form/action_button";
import QuestionnaireHeader from "./happiness_questionnaire_form/questionnaire_header";
import Copyright from "./happiness_questionnaire_form/copyright";
import {
  setQuestionnaireForm,
  removeQuestionnaireForm,
} from "../../../utils/strage_utils";
import {
  setDefaultPageCookie,
  removeDefaultPageCookie,
} from "../../../utils/cookie_utils";
import { isIosSafari } from "../../../utils/user_agent";
import HappinessInputErrorMessage from "./happiness_questionnaire_form/happiness_input_error_message";
import { HappinessQuestionnaireFormProps } from "../shared/HappinessQuestionnareType";
import QuestionnaireInput, {
  QuestionnaireInputDataType,
  createFormData,
} from "./happiness_questionnaire_form/questionnaire_input";
import LastPageAdditionalQuestions from "./happiness_questionnaire_form/last_page_additional_questions";
import LoadingImage from "../../shared/images/spin-gray.svg";

interface Props extends HappinessQuestionnaireFormProps {
  isReused: boolean;
  cookies: Cookies;
}

function HappinessQuestionnaireForm(props: Props): ReactElement {
  const {
    isReused,
    questionnaire,
    headerAreaIcon,
    user,
    event,
    errorMessages,
    isConsumer,
    isProfileInputAvailable,
    userProfile,
    birthYearOptions,
    birthMonthOptions,
    sexOptions,
    finalEducationOptions,
    professionOptions,
    personalAnnualIncomeOptions,
    householdAnnualIncomeOptions,
    maritalStatusOptions,
    childStatusOptions,
    prefectureOptions,
    countryOptions,
    isLoggedIn,
    selfIntroductionQuestions,
    selfIntroductionAnswers,
    departmentOptions,
    managerialPositionOptions,
    members,
    departments,
    userSetting,
    isDevelopmentRequestFormVisible,
    industryCategories,
    previousQuestionnaire,
    cookies,
  } = props;
  const [preparing, setPreparing] = useState(true);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<List<QuestionnaireInputDataType>>(
    List([])
  );
  const [showRequiredErrorMessage, setShowRequiredErrorMessage] =
    useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    const data = createFormData(questionnaire, isReused);
    const max = Math.max(...data.map((d) => d.page).toArray());
    if (max > 0) {
      const initialPage =
        data.filter((d) => !d.isSelected).first()?.page || max;
      setPage(initialPage);
    }
    setFormData(data);
    setPreparing(false);
  }, []);

  useEffect(() => {
    if (!preparing && errorMessages.length > 0) {
      animateScroll.scrollToBottom();
    }
  }, [preparing]);

  useEffect(() => {
    setShowErrorMessage(false);
    setShowRequiredErrorMessage(false);
  }, [page]);

  if (preparing) {
    return (
      <div className="relative mt-2">
        <img
          src={LoadingImage}
          style={{
            width: 50,
            height: 50,
            position: "absolute",
            top: "calc(50% + 80px)",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </div>
    );
  }

  let maxPage = Math.max(...formData.map((d) => d.page).toArray());
  if (maxPage === 0) {
    maxPage = 1;
  }
  const isWorkHappinessQuestionPage =
    formData.filter((d) => d.page === page).first()?.surveyType ===
    "workHappiness";

  const nextPage = () => {
    setQuestionnaireForm();
    setDefaultPageCookie(cookies);
    setPage(page + 1);
    window.scrollTo(0, 0);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  const clearTempForm = () => {
    if (
      window.confirm(
        window.I18n.t(
          "javascript.questionnaires.happiness_questionnaire.clear_confirm_message"
        )
      )
    ) {
      removeQuestionnaireForm();
      removeDefaultPageCookie(cookies);
      window.location.href = "/";
    }
  };

  const submitForm = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // railsのviewで指定しているID
    const form = document.forms.happinessQuestionnaireForm;
    const submit = document.getElementById("submit");
    if (form.checkValidity()) {
      removeQuestionnaireForm();
      removeDefaultPageCookie(cookies);
      setLoading(true);
      submit?.click();
    } else {
      if (isIosSafari) {
        setShowRequiredErrorMessage(true);
      }
      submit?.click();
    }
  };

  return (
    <React.Fragment>
      <QuestionnaireHeader
        isReused={isReused}
        isFirstPage={page === 1}
        isLastPage={page === maxPage}
        previousQuestionnaire={previousQuestionnaire}
        event={event}
        user={user}
        onPreviousPageClicked={previousPage}
        onTempFormCleared={clearTempForm}
        page={page}
        headerAreaIcon={headerAreaIcon}
        totalPage={maxPage}
        totalInputCount={formData.filter((d) => d.page > 0).toArray().length}
        allAnswerSelected={formData.every((d) => d.isSelected)}
        isWorkHappinessQuestionPage={isWorkHappinessQuestionPage}
      />
      {formData.map((d, i) => (
        <div
          key={d.page > 0 ? `question_${d.activeIndex}` : i}
          id={`question_${d.activeIndex}`}
        >
          <QuestionnaireInput
            currentPage={page}
            data={d}
            isConsumer={isConsumer}
            onSelect={() => {
              setFormData(
                formData.set(i, Object.assign({}, d, { isSelected: true }))
              );
            }}
          />
        </div>
      ))}

      {page === maxPage && (
        <LastPageAdditionalQuestions
          isConsumer={isConsumer}
          questionnaire={questionnaire}
          headerAreaIcon={headerAreaIcon}
          user={user}
          isProfileInputAvailable={isProfileInputAvailable}
          userProfile={userProfile}
          birthYearOptions={birthYearOptions}
          birthMonthOptions={birthMonthOptions}
          sexOptions={sexOptions}
          finalEducationOptions={finalEducationOptions}
          professionOptions={professionOptions}
          personalAnnualIncomeOptions={personalAnnualIncomeOptions}
          householdAnnualIncomeOptions={householdAnnualIncomeOptions}
          maritalStatusOptions={maritalStatusOptions}
          childStatusOptions={childStatusOptions}
          prefectureOptions={prefectureOptions}
          countryOptions={countryOptions}
          previousQuestionnaire={previousQuestionnaire}
          event={event}
          isLoggedIn={isLoggedIn}
          selfIntroductionQuestions={selfIntroductionQuestions}
          selfIntroductionAnswers={selfIntroductionAnswers}
          departmentOptions={departmentOptions}
          managerialPositionOptions={managerialPositionOptions}
          members={members}
          departments={departments}
          userSetting={userSetting}
          isDevelopmentRequestFormVisible={isDevelopmentRequestFormVisible}
          errorMessages={errorMessages}
          industryCategories={industryCategories}
        />
      )}
      {/* アンケート未入力エラーメッセージ */}
      {showErrorMessage && (
        <div className="d-flex justify-content-center">
          <div style={{ width: "80%" }}>
            {formData
              .filter((d) => d.page === page && !d.isSelected)
              .map((d, i) => {
                return (
                  <HappinessInputErrorMessage
                    key={d.activeIndex}
                    index={i}
                    activeIndex={d.activeIndex}
                  />
                );
              })}
          </div>
        </div>
      )}
      {/* サーバから返却されたエラーメッセージ */}
      {errorMessages.map((mes, i) => {
        const keyIndex = i;
        return (
          <div key={keyIndex} className="invalid-feedback d-block">
            <span>{mes}</span>
          </div>
        );
      })}
      {/* ブラウザのバリデーションがうまく動かない場合に対応したエラーメッセージ */}
      {showRequiredErrorMessage && !loading && (
        <div
          className="invalid-feedback d-block mx-auto my-2"
          style={{ width: "80%" }}
        >
          <span>
            {window.I18n.t(
              "javascript.questionnaires.happiness_questionnaire.required_question_error_message1"
            )}
            <br />
            {window.I18n.t(
              "javascript.questionnaires.happiness_questionnaire.required_question_error_message2"
            )}
          </span>
        </div>
      )}
      <ActionButton
        isLastPage={page === maxPage}
        onSubmit={submitForm}
        onActiveNextPageClicked={nextPage}
        onDisabledNextPageClicked={() => setShowErrorMessage(true)}
        allInputtingFormSelected={formData
          .filter((d) => d.page === page)
          .every((d) => d.isSelected)}
        loading={loading}
      />
      <Copyright
        isFirstPage={page === 1}
        isWorkHappinessPage={isWorkHappinessQuestionPage}
      />
    </React.Fragment>
  );
}
export default withCookies(HappinessQuestionnaireForm);
