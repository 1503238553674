import React, { ReactElement } from "react";
import { QuestionnaireNewResponse } from "../../../../../../../models/QuestionnaireApi";
import MultiLineText from "../../../../../../shared/multi_line_text";
import SelectForm from "../../../../../../shared/form/select_form";
import { EventResponse } from "../../../../../../../models/Event";

interface Props {
  isConsumer: boolean;
  event: EventResponse | null;
  questionnaire: QuestionnaireNewResponse;
}

const options = [
  {
    key: 1,
    value: window.I18n.t(
      "javascript.questionnaires.happiness_questionnaire.performance_check_option1"
    ),
  },
  {
    key: 2,
    value: window.I18n.t(
      "javascript.questionnaires.happiness_questionnaire.performance_check_option2"
    ),
  },
  {
    key: 3,
    value: window.I18n.t(
      "javascript.questionnaires.happiness_questionnaire.performance_check_option3"
    ),
  },
  {
    key: 4,
    value: window.I18n.t(
      "javascript.questionnaires.happiness_questionnaire.performance_check_option4"
    ),
  },
  {
    key: 5,
    value: window.I18n.t(
      "javascript.questionnaires.happiness_questionnaire.performance_check_option5"
    ),
  },
  {
    key: 6,
    value: window.I18n.t(
      "javascript.questionnaires.happiness_questionnaire.performance_check_option6"
    ),
  },
  {
    key: 7,
    value: window.I18n.t(
      "javascript.questionnaires.happiness_questionnaire.performance_check_option7"
    ),
  },
];

export default function RelatedIndicatorsForm(
  props: Props
): ReactElement | null {
  const { isConsumer, event, questionnaire } = props;
  if (!isConsumer) {
    return null;
  }
  if (
    event !== null &&
    event.profile_input_for_research === "hide_profile_input_for_research"
  ) {
    return null;
  }
  return (
    <>
      <div className="font-weight-bold text-center mt-4">
        <MultiLineText
          text={window.I18n.t(
            "javascript.questionnaires.happiness_questionnaire.individual_performance_point_question"
          )}
        />
      </div>
      <div className="mt-2">
        <SelectForm
          model={questionnaire}
          scope="questionnaire"
          options={options}
          columnName="individual_performance_point"
          hideLabel
          option={{
            include_blank: window.I18n.t(
              "activerecord.attributes.choice.blank_role_body"
            ),
          }}
        />
      </div>
      <div className="font-weight-bold text-center mt-4">
        <MultiLineText
          text={window.I18n.t(
            "javascript.questionnaires.happiness_questionnaire.team_performance_point_question"
          )}
        />
      </div>
      <div className="mt-2">
        <SelectForm
          model={questionnaire}
          scope="questionnaire"
          options={options}
          columnName="team_performance_point"
          hideLabel
          option={{
            include_blank: window.I18n.t(
              "activerecord.attributes.choice.blank_role_body"
            ),
          }}
        />
      </div>
    </>
  );
}
